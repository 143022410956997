<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Categorias" subtitle="---"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-autocomplete
            ref="AutomcompleteCategories"
            v-model="detail.categories"
            :loading="loading"
            :items="categories"
            item-text="name"
            cache-items
            flat
            hide-no-data
            return-object
            chips
            multiple
            label="Seleccione las categorias que incluye su producto"
          ></v-autocomplete>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveCategories"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Etiquetas" subtitle="---"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <template v-if="tags.length > 0">
            <v-autocomplete
              v-model="tag"
              :items="tags"
              hint="Recuerde usar solo minisculas"
              item-text="name"
              cache-items
              flat
              hide-no-data
              return-object
              chips
              multiple
              label="Etiquetas"
              class="mb-4"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="tagRelated"
              :items="tag"
              label="Productos de esta misma serie"
              item-text="name"
              return-object
              chips
              multiple
              class="mb-3"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="tagFlowers"
              :items="tag"
              label="Flores Similares"
              item-text="name"
              chips
              multiple
              return-object
            >
            </v-autocomplete>
          </template>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveCategories"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Articulos de blog relacionados" subtitle="---"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-combobox v-model="posts" :items="postsAll" item-text="name" chips multiple label="Articulos de blog">
            <template slot="selection" slot-scope="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                :selected="data.selected"
                :disabled="data.disabled"
                class="v-chip--select-multi"
                @click.stop="data.parent.selectedIndex = data.index"
                @input="data.parent.selectItem(data.item)"
              >
                <span v-html="data.item.name"></span>
              </v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <span v-text="data.item.name"></span>
            </template>
          </v-combobox>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="savePostsProduct"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Packs" subtitle="Agregue productos adicionales a la venta"></titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-combobox
            v-model="packs"
            :items="productsAll"
            item-text="name"
            chips
            multiple
            label="Productos incluidos en el pack"
          >
            <template slot="selection" slot-scope="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                :selected="data.selected"
                :disabled="data.disabled"
                class="v-chip--select-multi"
                @click.stop="data.parent.selectedIndex = data.index"
                @input="data.parent.selectItem(data.item)"
              >
                <v-avatar :class="data.item.status ? 'success white--text' : 'error white--text'">
                  {{ data.item.name.slice(0, 1).toUpperCase() }}
                </v-avatar>
                <span v-html="data.item.name"></span>
              </v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <span v-html="data.item.name"></span> -
              <span v-if="data.item.status" class="caption ml-2">(Activo)</span>
              <span v-else class="caption ml-2">(Inactivo)</span>
            </template>
          </v-combobox>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="savePacksProduct"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs12 lg12 md12>
      <v-card>
        <titleCard title="Producto unido" subtitle="Al mostrarse en el listado, estara unido al producto ingresado">
        </titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-combobox v-model="unido" :items="productsAll" item-text="name" label="Listado de productos">
            <template slot="selection" slot-scope="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                :selected="data.selected"
                :disabled="data.disabled"
                class="v-chip--select-multi"
                @click.stop="data.parent.selectedIndex = data.index"
                @input="data.parent.selectItem(data.item)"
              >
                <v-avatar :class="data.item.status ? 'success white--text' : 'error white--text'">
                  {{ data.item.name.slice(0, 1).toUpperCase() }}
                </v-avatar>
                <span v-html="data.item.name"></span>
              </v-chip>
            </template>
            <template slot="item" slot-scope="data">
              <span v-html="data.item.name"></span> -
              <span v-if="data.item.status" class="caption ml-2">(Activo)</span>
              <span v-else class="caption ml-2">(Inactivo)</span>
            </template>
          </v-combobox>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-spacer></v-spacer>
          <v-btn color="accent" flat @click="saveUnidoProduct"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG, GET_PRODUCT, GET_PRODUCTS_ALL_MIN, GET_BLOG } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'ProductSeo',
  components: { titleCard },
  props: ['product', 'getProduct'],
  data: () => ({
    tag: null,
    tags: [],
    packs: null,
    posts: null,
    tagRelated: [],
    tagFlowers: null,
    unido: null,
    categories: [],
    productsAll: [],
    selectCategories: [],
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    result: [],
    delay: 400,
    clicks: 0,
    timer: null
  }),
  watch: {
    product() {
      this.initComponent()
    }
  },
  mounted() {
    this.initComponent()
  },
  methods: {
    async getCategoriesAndTags() {
      try {
        const res = await this.$http.get(`${CONFIG}/categories-tags`)
        this.categories = res.data.categories
        this.tags = res.data.tags
        this.checkTags()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getPosts() {
      const res = await this.$http.get(`${GET_BLOG}`)
      this.postsAll = res.data
      this.checkBlog()
    },
    async getProducts() {
      const res = await this.$http.get(`${GET_PRODUCTS_ALL_MIN}`)
      this.productsAll = res.data
      this.checkUnidos()
    },
    async saveCategories() {
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.detail.id}/categories`, {
          tag: this.tag,
          categories: this.detail.categories,
          tag_related: this.tagRelated ? this.tagRelated : 0,
          tag_flowers: this.tagFlowers ? this.tagFlowers : 0
        })
        this.getProduct()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Categorias y etiquetas actualizado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async savePostsProduct() {
      try {
        const posts = this.posts.map(item => item.id)
        await this.$http.put(`${GET_PRODUCT}/${this.detail.id}/posts`, {
          posts
        })
        this.getProduct()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Aticulos actualizados'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async savePacksProduct() {
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.detail.id}/packs`, {
          packs: this.packs
        })
        this.getProduct()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Packs actualizado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveUnidoProduct() {
      const unido = this.unido ? this.unido.id : this.detail.id
      try {
        await this.$http.put(`${GET_PRODUCT}/${this.detail.id}/unidos`, {
          unido
        })
        this.getProduct()
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Producto unido actualizado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    checkTags() {
      const { tags } = this
      this.tagRelated = []
      this.tagFlowers = []
      if (this.detail.tags !== undefined) {
        const tagsA = []
        this.detail.tags.forEach(element => {
          const check = this._.filter(tags, ['id', element])
          tagsA.push(check[0])
        })
        this.tag = tagsA
      }
      if (this.detail.tag_related !== undefined) {
        this.detail.tag_related.forEach(element => {
          const tagRelated = this._.filter(this.tag, ['id', element])
          if (tagRelated.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.tagRelated.push(tagRelated[0])
          }
        })
      }
      if (this.detail.tag_flowers !== undefined) {
        this.detail.tag_flowers.forEach(element => {
          const tagFlowers = this._.filter(this.tag, ['id', element])
          if (tagFlowers.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.tagFlowers.push(tagFlowers[0])
          }
        })
      }
    },
    checkBlog() {
      const { product } = this
      const posts = []
      if (product.post_related.length > 0) {
        product.post_related.forEach(e => {
          const post = this._.filter(this.postsAll, ['id', e])
          if (post.length > 0) {
            posts.push(post[0])
          }
        })
        this.posts = posts
      }
      console.log(product)
    },
    checkUnidos() {
      const productUnido = this._.filter(this.productsAll, ['id', this.detail.unidos])
      this.unido = productUnido[0] ? productUnido[0] : null
    },
    initComponent() {
      this.detail = this.product
      this.tag = this.detail.tags
      this.checkTags()
      this.getCategoriesAndTags()
      this.getProducts()
      this.getPosts()
    },
    oneClick(data) {
      this.clicks++
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          console.log('click')
          this.clicks = 0
        }, this.delay)
      } else {
        clearTimeout(this.timer)
        const routeData = this.$router.resolve({ name: 'tagsId', params: { id: data.item.id } })
        window.open(routeData.href, '_blank')
        this.clicks = 0
      }
    }
  }
}
</script>
